@import './Animations.css';

.order-container {
	padding: 20px 20px 30px 20px;
	max-width: 800px;
	margin: 0 auto 30px auto;
	background-color: #ffffff;
	border-radius: 10px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.order-title {
	font-size: 2rem;
	text-align: center;
	margin-bottom: 10px;
	color: #3703c5;
	animation: fadeInDown 1s ease-in-out;
}

.order-subtitle {
	font-size: 1rem;
	margin-bottom: 20px;
	color: #555;
	text-align: left;
}

.order-list {
	list-style: none;
	padding: 0;
	animation: fadeInUp 1s ease-in-out;
}

.order-item {
	background-color: #f1f1f1;
	padding: 10px;
	margin: 15px 0;
	border-radius: 8px;
	transition: transform 0.3s ease, box-shadow 0.3s ease;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.order-item:hover {
	transform: scale(1.025);
	background-color: #e0e0e0;
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.order-content {
	display: flex;
	justify-content: space-between;
	width: 100%;
}		

.order-product-text {
	margin: 5px;
}

.order-product-text h2 {
	margin: 0 0 10px 0;
	color: #3703c5;
}

.order-product-text p {
	margin: 0px;
	margin-bottom: 10px;
	color: #555;
}

.order-product-thumbnail {
	height: 76px;
}

.order-product-thumbnail img {
	width: 76px;
	height: 76px;
	border-radius: 5px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.quantity-cost-section {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 20px;
	animation: fadeInUp 1s ease-in-out;
}

.quantity-section {
	display: flex;
	flex-direction: column;
	margin: 0 10px 0 0;
	padding-inline-start: 10px;
	background-color: #f9f9f9;
	padding: 20px;
	border-radius: 10px;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
	width: 60%;
}

.quantity-section h3 {
	margin-top: 0;
}

.quantity-item {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	margin-left: 5px;
}

.quantity-item label {
	margin-left: 5px;
}

.cost-summary-card {
	display: flex;
	flex-direction: column;
	padding-inline-start: 10px;
	background-color: #f9f9f9;
	padding: 20px;
	border-radius: 10px;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
	width: 40%;
}

.cost-summary-card h3 {
	margin-top: 0;
}

.cost-summary-card .summary-line {
	display: flex;
	justify-content: space-between;
}

.cost-summary-card .label {
	flex: 1;
	text-align: left;
}

.cost-summary-card .value {
	flex: 1;
	text-align: right;
}

.cost-summary-card p {
	margin: 3px;
}

.order-status {
	font-size: 1rem;
	margin-bottom: 0px;
	color: #555;
	text-align: left;
}

/* on mobile: less padding and margins, vertical cost-section, small thumnnails */
@media (max-width: 768px) {
	.order-container {
		padding: 10px 10px 15px 10px;
	}

	.order-product-thumbnail img {
		width: 50px;
		height: 50px;
	}

	.quantity-cost-section {
		flex-direction: column;
	}	

	.quantity-section {
		width: auto;
		margin: 0;
	}

	.cost-summary-card {
		margin-top: 15px;
		width: auto;
	}
}
