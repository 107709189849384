@import './Animations.css';

body {
	font-family: 'Open Sans', sans-serif;
	margin: 0;
	padding: 0;
	background-color: #f9f9f9;
}

.tutorials-container {
	padding: 20px 20px 30px 20px;
	max-width: 800px;
	margin: 0 auto 30px auto;
	background-color: #ffffff;
	border-radius: 10px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.tutorials-title {
	font-size: 2.0rem;
	text-align: center;
	color: #3703c5;
	position: relative;
	animation: fadeInDown 1s ease-in-out;
}

.tutorials-subtitle {
	font-size: 1rem;
	margin-bottom: 20px;
	color: #3703c5;
	text-align: center;
}

.tutorials-list {
	list-style: none;
	padding: 0;
	animation: fadeInUp 1s ease-in-out;
}

.tutorial-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #f1f1f1;
	padding: 5px;
	margin: 15px 0;
	border-radius: 8px;
	color: #3703c5;
	cursor: pointer;
	transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.tutorial-content {
	width: 100%;
}

.tutorial-item h2 {
	color: #3703c5;
	margin: 0;
	padding: 5px;
}

.tutorial-item img {
	float: right;
	margin-left: 10px;
	width: 65px;
	height: 65px;
	border-radius: 5px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.tutorial-item p {
	margin: 0 0 20px 0;
	color: #555;
}

.tutorial-container {
	display: none;
	animation: fadeInDown 0.5s ease-in-out;
}

/* set padding for a 16:9 aspect ratio (with height:0 and position: relative) */
.video-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;
	background-color: #000;
	border-radius: 8px;
}

.video-container iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;
}

.tutorial-item.selected {
	background-color: #e0e0e0;
}

.tutorial-item.selected h2 {
	margin: 0 0 10px 0;
}

.tutorial-item.selected .tutorial-container {
	display: block;
}

.tutorial-item:hover {
	transform: translateY(-5px);
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* on mobile: less padding and margins */
@media (max-width: 768px) {
	.tutorials-container {
		padding: 10px 10px 15px 10px;
	}
}
