body {
	font-family: 'Open Sans', sans-serif;
	margin: 0;
	padding: 0;
	background-color: #f9f9f9;
}

.hamburger-container {
	position: fixed;
	top: 15px;
	left: 15px;
	cursor: pointer;
	z-index: 1100;
}

.hamburger-icon {
	width: 30px;
	height: 25px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.hamburger-icon .line {
	height: 4px;
	background-color: #6e45e2;
	border-radius: 3px;
	transition: all 0.3s ease;
}

.navbar {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background: linear-gradient(135deg, #6e45e2, #88d3ce);
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	z-index: 1000;
	transform: translateY(-100%);
	transition: transform 0.3s ease-in-out;
	display: flex;
	justify-content: center;
}

.navbar.open {
	transform: translateY(0);
}

.navbar-container {
	display: flex;
	justify-content: center;
	padding: 15px;
}

.nav-link {
	margin: 0 15px;
	color: #fff;
	text-decoration: none;
	font-size: 18px;
	font-weight: 600;
	transition: color 0.3s ease, transform 0.3s ease;
}

.nav-link:hover {
	color: #ffd700;
	transform: scale(1.1);
}

.content {
	padding: 20px;
}

/* on mobile: vertical Navbar, less padding and margins */
@media (max-width: 768px) {
	.navbar-container {
		flex-direction: column;
		align-items: center;
	}

	.nav-link {
		margin: 10px 0;
	}

	.content {
		padding: 5px;
	}
}
