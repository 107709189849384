@import './Animations.css';

body {
	font-family: 'Open Sans', sans-serif;
	margin: 0;
	padding: 0;
	background-color: #f9f9f9;
}

.products-container {
	padding: 20px 20px 30px 20px;
	max-width: 800px;
	margin: 0 auto 30px auto;
	background-color: #ffffff;
	border-radius: 10px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.products-title {
	font-size: 2rem;
	text-align: center;
	margin-bottom: 10px;
	color: #3703c5;
	position: relative;
	animation: fadeInDown 1s ease-in-out;
}

.trademark {
	font-size: 1rem;
	vertical-align: super;
}

.products-subtitle {
	font-size: 1rem;
	margin-bottom: 20px;
	color: #3703c5;
	text-align: center;
}

.products-list {
	list-style: none;
	padding: 0;
	animation: fadeInUp 1s ease-in-out;
}

.product-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #f1f1f1;
	padding: 5px;
	margin: 15px 0;
	border-radius: 8px;
	cursor: pointer;
	transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-content {
	width: 100%;
}

.product-item.selected {
	background-color: #e0e0e0;
}	

.product-item h2 {
	margin: 0;
	color: #3703c5;
	padding: 5px;
}

.product-item p {
	margin: 0;
	padding: 5px
}

.product-item #product-description {
	color: #555;
	padding: 5px;
	display: none;
}

.product-item img {
	float: right;
	margin-left: 10px;
	width: 65px;
	height: 65px;
	border-radius: 5px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.product-item.selected #product-description {
	display: block;
}

.product-item.selected img {
	width: 125px;
	height: 125px;
}

.product-item:hover {
	transform: translateY(-5px);
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* on mobile: less padding and margins */
@media (max-width: 768px) {
	.products-container {
		padding: 10px 10px 15px 10px;
	}

	.product-item.selected img {
		margin-bottom: 5px;
	}
}
