.footer {
	text-align: center;
	padding: 0;
	background-color: #333;
	color: #fff;
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
}

.footer p {
	margin-top: 9px;
	margin-bottom: 7px;
}
