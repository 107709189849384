@import './Animations.css';

h2, h3, h4, h5, h6 {
	color: #3703c5;
}

body {
	font-family: 'Open Sans', sans-serif;
	margin: 0;
	padding: 0;
	background-color: #f9f9f9;
}

.home-container {
	padding: 20px 20px 30px 20px;
	max-width: 800px;
	margin: 0 auto 30px auto;
	background-color: #ffffff;
	border-radius: 10px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.hero-section {
	background: linear-gradient(135deg, #3703c5, #88d3ce);
	padding: 20px 20px;
	color: #fff;
	border-radius: 10px;
	margin: 10px 0 10px 0;
	position: relative;
	animation: fadeInDown 1s ease-in-out;
}

.hero-title {
	font-size: 2.5rem;
	margin: 15px;
	text-align: center;
}

.hero-subtitle {
	font-size: 1.3rem;
	margin-bottom: 20px;
}

.hero-nav-link {
	display: flex;
	justify-content: center;
	color: #fff;
	text-decoration: none;
	font-size: 18px;
	font-weight: 600;
	margin-top: 20px;
}

.hero-button {
	padding: 12px 25px;
	background-color: #ff914d;
	color: white;
	border: none;
	border-radius: 5px;
	font-size: 1.1rem;
	cursor: pointer;
	transition: background-color 0.3s ease, transform 0.3s ease;
}

.hero-button:hover {
	background-color: #e07b2f;
	transform: scale(1.05);
}

.about-latin {
	background-color: #fff;
	padding: 20px 20px;
	margin-bottom: 20px;
	border-radius: 10px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
	animation: fadeInUp 1s ease-in-out;
}

.about-latin-section {
	text-align: center;
	margin-bottom: 15px;
	height: auto;
}

.about-card {
	background-color: #f1f1f1;
	padding: 10px;
	border-radius: 10px;
	transition: transform 0.3s ease, background-color 0.3s ease;
	height: auto;
}

.about-card:hover {
	transform: scale(1.025);
	background-color: #e0e0e0;
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.about-card-cursor-pointer {
	cursor: pointer;
}

.phrase-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px 0 20px 0;
}

.phrase-container .fade {
	opacity: 0;
	transition: opacity 0.5s ease-in-out;
	position: absolute;
}

.phrase-container .fade-in {
	opacity: 1;
}

.phrase-latin,
.phrase-meaning {
	font-size: 1.75rem;
	color: #333;
	width: 95%;
}

.phrase-instruction {
	font-size: 0.9rem;
	color: #888;
	margin-top: 30px;
}

.about-latin-content {
	display: flex;
	flex-direction: row;
	align-items: center;
	text-align: left;
	margin: 10px;
}

.latin-benefits {
	display: flex;
	flex-direction: column;
	margin: 0px;
}

.latin-benefits p {
	display: flex;
	flex-direction: column;
	margin: 7px 10px 7px 0px;
}

.latin-image {
	display: flex;
	margin: 10px 0;
}

.latin-image img {
	width: 100%;
	max-width: 250px;
	border-radius: 10px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.testimonials {
	background-color: #f0f0f0;
	padding: 10px;
	margin-bottom: 40px;
	border-radius: 10px;
}

.testimonial-container {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #f1f1f1;
	padding: 10px;
	border-radius: 10px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
	transition: transform 0.3s ease, background-color 0.3s ease;
}

.testimonial-container:hover {
	transform: scale(1.025);
	background-color: #e0e0e0;
}


blockquote {
	font-size: 1.2rem;
	font-style: italic;
	color: #555;
	margin: 20px;
	text-align: center;
}

blockquote span {
	display: block;
	margin-top: 10px;
	font-weight: bold;
	color: #333;
}

.message-section {
	background-color: #fff;
	padding: 10px 20px;
	border-radius: 10px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* on mobile: less padding and margins, vertical latin-benefits */
@media (max-width: 768px) {
	.home-container {
		padding: 10px 10px 15px 10px;
	}

	.hero-section {
		margin-top: 35px;
	}

	.hero-title {
		margin: 0;
	}
	
	.about-latin {
		padding: 10px;
	}

	.about-latin-content {
		flex-direction: column;
	}

	.phrase-container {
		padding: 40px 0 40px 0;
	}

	.message-section {
		margin-bottom: 20px;
	}
}
