@import './Animations.css';

body {
	font-family: 'Open Sans', sans-serif;
	margin: 0;
	padding: 0;
	background-color: #f9f9f9;
}

.about-container {
	padding: 20px 20px 30px 20px;
	max-width: 800px;
	margin: 0 auto 30px auto;
	background-color: #ffffff;
	border-radius: 10px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-title {
	font-size: 2rem;
	text-align: center;
	margin-bottom: 10px;
	color: #3703c5;
	animation: fadeInDown 1s ease-in-out;
}

.about-description {
	font-size: 1.0rem;
	text-align: center;
	margin: 20px 10px;
	color: #555;
	animation: fadeIn 1s ease-in-out;
	text-align: left;
}

.about-section {
	background-color: #f1f1f1;
	padding: 15px 15px;
	margin-bottom: 20px;
	border-radius: 10px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
	animation: fadeInUp 1s ease-in-out;
	transition: transform 0.3s ease, background-color 0.3s ease;
}

.about-section:hover {
	transform: scale(1.025);
	background-color: #e0e0e0;
}

.section-title {
	font-size: 1.75rem;
	text-align: center;
	margin: 20px;
	color: #3703c5;
}

.bio-section {
	margin-bottom: 20px;
}

.bio-content {
	display: flex;
	align-items: center;
	animation: fadeIn 1s ease-in-out;
}

.bio-content.reverse {
	flex-direction: row-reverse;
}

.bio-image {
	margin: 5px 10px 5px 5px;
}

.bio-image img {
	width: 100%;
	max-width: 300px;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.bio-text {
	flex: 1;
}

.bio-text p {
	margin: 5px 5px 10px 5px;
	font-size: 1rem;
	line-height: 1.6;
	color: #333;
}

@media (max-width: 768px) {
}

/* on mobile: less padding on the main container, vertical bio content */
@media (max-width: 768px) {
	.about-container {
		padding: 10px 10px 15px 10px;
	}
	
	.about-section {
		padding: 10px 5px;
	}
	
	.about-title {
		margin-top: 30px;
	}

	.bio-content {
		flex-direction: column;
	}

	.bio-content.reverse {
		flex-direction: column;
	}

	.bio-text {
		margin-left: 0;
	}

	.bio-image {
		margin: 5px;
	}
}
