.order {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.order input {
	padding: 10px;
	font-size: 1rem;
	border-radius: 5px;
	border: 1px solid #ddd;
	margin-bottom: 15px;
	width: auto;
}

.order textarea {
	padding: 10px;
	font-size: 1rem;
	border-radius: 5px;
	border: 1px solid #ddd;
	margin-bottom: 15px;
	width: auto;
	height: 50px;
}

.order button[type="submit"] {
	width: auto;
	padding: 10px;
	background-color: #3703c5;
	color: white;
	border: none;
	border-radius: 5px;
	font-size: 1.0rem;
	cursor: pointer;
}

.order button:hover {
	background-color: #5a3db7;
}
