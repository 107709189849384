.carousel-container {
	overflow-x: auto;
	white-space: nowrap;
	width: 100%;
	display: flex;
	align-items: center;
}

.carousel {
	display: flex;
}

.cell {
	min-width: var(--cell-width, 300px);
	margin: 10px;
	background-color: #f0f0f0;
	padding: 20px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	overflow-wrap: break-word;
	word-wrap: break-word;
	word-break: break-word;
	white-space: normal;
}

/* on mobile: less padding */
@media (max-width: 768px) {
	.cell {
		padding: 5px;
	}
}
